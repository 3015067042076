import {BREAK_POINTS} from '../setting';

export default function() {

	function announceText() {
		const $announceText = $('.c-announce__txt')
		const textCount = $('.c-announce__txt').text().length;
		if (window.innerWidth < BREAK_POINTS.ltSP) {
			if(textCount > 23) {
				$announceText.addClass('js-announce');
			}
		}else {
			$announceText.removeClass('js-announce');
		}
	}

	$(window).bind('load resize orientationchange', function () {
		announceText()
	});
}
