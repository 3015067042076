export default function () {
  let $elem = $('.change');
	let sp = '_sp.';
	let pc = '_pc.';
	let replaceWidth = 768;

	function imageSwitch() {
		let windowWidth = parseInt($(window).width());
		$elem.each(function () {
			if (windowWidth >= replaceWidth) {
				$(this).attr('src', $(this).attr('src').replace(sp, pc));
			} else {
				$(this).attr('src', $(this).attr('src').replace(pc, sp));
			}
		});
	}
	imageSwitch();

	let delayStart;
	let delayTime = 0;
	$(window).bind('resize orientationchange', function () {
		clearTimeout(delayStart);
		delayStart = setTimeout(function () {
			imageSwitch();
		}, delayTime);
	});
};
