export default function() {

  $('.js-modal-open').on('click', function(e) {
    e.preventDefault();
    let $target = $($(this).attr('data-target'));
    $target.fadeIn();
    $('#js-modal-overlay').fadeIn();
		$('body').css({
			overflow: "hidden"
		})
  });

  $('.js-modal-close,#js-modal-overlay').on('click', function(e) {
    $('.js-modal').fadeOut();
    $('#js-modal-overlay').fadeOut();
		$('body').css({
			overflow: "auto"
		})
  });
}
