import {BREAK_POINTS} from '../setting';

export default function() {
	function acc01() {
		const $acc_trigger = $('.js-acc-trigger');
		const $acc_target = $('.js-acc-target');

    $acc_trigger.click(function(){
			$(this).toggleClass('is-acc-open');
			$($(this).attr("data-content")).slideToggle();
		});
  }

	function acc02() {
    const $clsAcc = $('.js-acc');
    const clsAccOpen = 'is-acc-open';

    $clsAcc.each(function () {
      if (!$(this).hasClass(clsAccOpen)) {
        $(this).next().hide();
      }
    });
    $clsAcc.on('click', function () {
      $(this).next().slideToggle(400);
      $(this).toggleClass(clsAccOpen);
      return false;
    });
  }

	function accSP() {
		const $accSP_trigger = $('.js-accSP-trigger');
		const $accSP_target = $('.js-accSP-target');

		if (window.innerWidth < BREAK_POINTS.ltSP) {
			$accSP_trigger.click(function(){
				$(this).toggleClass('is-accSP-open');
				$($(this).attr("data-content")).slideToggle();
			});
		}
  }


	function inputSelect() {
		const $orderPayment = $('.c-order-payment__body');
		const $credit = $('.js-open-credit');
		const $arm = $('.js-open-atm');
		const $convenienceStore = $('.js-open-convenienceStore');
		const $cashOnDelivery = $('.js-open-cashOnDelivery');

		$('.js-checked input#credit[type=radio]').click(function() {
			if ($(this).prop('checked')) {
				$orderPayment.show();
				$credit.show();
				$arm.hide();
				$convenienceStore.hide()
				$cashOnDelivery.hide();
			}
		});
		$('.js-checked input#atm[type=radio]').click(function() {
			if ($(this).prop('checked')) {
				$orderPayment.show();
				$arm.show();
				$credit.hide();
				$convenienceStore.hide()
				$cashOnDelivery.hide();
			}
		});
		$('.js-checked input#convenienceStore[type=radio]').click(function() {
			if ($(this).prop('checked')) {
				$orderPayment.show();
				$credit.hide();
				$arm.hide();
				$convenienceStore.show();
				$cashOnDelivery.hide();
			}
		});
		$('.js-checked input#cashOnDelivery[type=radio]').click(function() {
			if ($(this).prop('checked')) {
				$orderPayment.show();
				$credit.hide();
				$arm.hide();
				$convenienceStore.hide();
				$cashOnDelivery.show();
			}
		});
	}

	$(function () {
		const anchor = location.hash;
		$(anchor).addClass('is-acc-open');

		acc01();
		acc02();
		accSP();
		inputSelect();
	});
}
