import {BREAK_POINTS} from '../setting';

export default function() {
	function fixed_nav() {
		if ($('.c-side-nav').hasClass('js-side-nav')) {
			if(window.innerWidth > BREAK_POINTS.ltSP) {
				const scroll = $(document).scrollTop();
				const $side_nav = $('.js-side-nav')
				const content_top = $('.l-content').offset().top;
				const side_left = $('.js-side-nav').offset().left;
				const side_width = $('.l-sidebar').innerWidth();
				const side_height = $('.js-side-nav').outerHeight();
				const side_margintop = 50;
				const side_marginbottom = 50;
				const footer_top = $('.c-footer-nav,.js-sidebar-foot').offset().top;

				if (scroll > (footer_top - side_marginbottom - side_margintop - side_height)) {
					$side_nav.css({
						'position': 'absolute',
						'top': 'auto',
						'bottom': '50px',
						'left': 'auto',
						'width': side_width,
					});
				} else if (scroll > (content_top - side_margintop)) {
					$side_nav.css({
						'position': 'fixed',
						'top': '50px',
						'bottom': 'auto',
						'left': side_left,
						'width': side_width,
					});
				} else {
					$side_nav.css({
						'position': 'static',
						'top': 'auto',
						'bottom': 'auto',
						'left': 'auto',
						'width': side_width,
					});
				}
			}
		}
	}

	$(window).bind('load scroll resize orientationchange', function () {
		fixed_nav();
	});
}
