import nav from "./module/nav"
import changeImage from "./module/changeImage"
import announce from "./module/announce"
import side_nav from "./module/side_nav"
import accordion from "./module/accordion"
import utility from "./module/utility"
import modal from "./module/modal"
import drift from "./module/drift"

utility();
nav();
changeImage();
announce();
side_nav();
accordion();
modal();

if($('.drift-img').length) {
	drift();
}
