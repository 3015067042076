import {BREAK_POINTS} from '../setting';

export default function() {
	function smoothScroll() {
		const header_h = $('.nav-right-fixed').outerHeight();
		const urlHash = location.hash;
		if(urlHash) {
			setTimeout(function(){
				const target = $(urlHash);
				const position = target.offset().top - header_h;
				$('body,html').stop().scrollTop(position);
			}, 100);
		}
		$('a:not(".no-scroll")').click(function(){
			const href= $(this).attr("href");
			const target = $(href);
			const position = target.offset().top;
			const position2 = target.offset().top - header_h;
			$('body,html').stop().animate({scrollTop:position}, 500);
			if($('.l-header').children().hasClass('l-nav')){
				$('body,html').stop().animate({scrollTop:position2}, 500);
			}
			return false;
		});
	};

	function pageHeader() {
		const $page_header = $('.l-page-header,.l-page-header-02')
		const breadcrumb_h = $('.c-breadcrumb').outerHeight();
		$page_header.css({
			marginTop: '-' + breadcrumb_h + 'px'
		})
	}

	function matchHeight() {
		$('.js-autoHeight').matchHeight();
		$('.c-bundled-items-02__body').matchHeight();
		$('.c-product-03__body').matchHeight();
		$('.c-product-07__body').matchHeight();
		if(window.innerWidth > BREAK_POINTS.ltSP) {
			$('.c-category-01__body').matchHeight();
			$('.c-category-02__body').matchHeight();
			$('.c-review-01__body').matchHeight();
			$('.c-product-01__body').matchHeight();
			$('.c-product-02__body').matchHeight();
			$('.c-contact-01__body').matchHeight();
			$('.c-review-02__txt').matchHeight();
			$('.c-drink__img').matchHeight();
		};
	}

	function floatingBtn() {
		const scrollShow = $('.js-floatingBtn');
		scrollShow.hide();
		$(window).scroll(function () {
			const docH = $(document).height();
			const winH = $(window).height() + $(window).scrollTop();
			if((docH - winH) / winH <= 0.05) {
				scrollShow.fadeOut();
			}else if($(this).scrollTop() < 100) {
				scrollShow.fadeOut();
			}else if($(this).scrollTop() > 100) {
				scrollShow.fadeIn();
			}
		});
	}

	/* $.ready */
	$(function() {
		smoothScroll();
		pageHeader();
		floatingBtn();
	});

	/* $.load */
	$(window).bind('load resize orientationchange', function () {
		matchHeight();
	});
}
