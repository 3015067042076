import {BREAK_POINTS} from '../setting';

export default function() {
	const $navBtn = $('.js-gnav');
	const $navWrapper = $('.js-gnav-wrapper');
	const $navTarget = $('.js-gnav-target');
	const $navTargetLink = $('.nav-main li:nth-child(3) a');
	const $subNav = $('.js-nav-sub')

	function setHeaderNav() {
		if(window.innerWidth < BREAK_POINTS.ltSP) {
			$navBtn.on('click', function(){
				$(this).toggleClass('is-active');
				$navTarget.slideToggle();
				if ($(this).hasClass('is-active')) {
					$('body').css({overflow:'hidden'});
					$navWrapper.attr({'aria-expanded':'false'});
					$navTarget.attr({'aria-hidden':'true'});
					$navTarget.addClass('is-show');
					$navBtn.children('.menu-txt').text('CLOSE')
				} else {
					$('body').css({overflow:'auto'});
					$navWrapper.attr({'aria-expanded':'true'});
					$navTarget.attr({'aria-hidden':'false'});
					$navTarget.removeClass('is-show');
					$navBtn.children('.menu-txt').text('MENU');
				};
			});
		}
	}

	function closeBtn() {
		if(window.innerWidth < BREAK_POINTS.ltSP) {
			$navTargetLink.on('click',function(){
				$('body').css({overflow:'auto'});
				$navWrapper.attr({'aria-expanded':'true'});
				$navTarget.attr({'aria-hidden':'false'});
				$navTarget.removeClass('is-show');
				$navBtn.children('.menu-txt').text('MENU');
				$navTarget.slideUp();
				$navBtn.removeClass('is-active');
			});
		}
	}

	function changeNav() {
		if(window.innerWidth < BREAK_POINTS.ltSP) {
			const scroll = $(window).scrollTop();
			const h = $('.l-header').outerHeight();
			if (scroll >= h){
				$('.nav-member').addClass('is-change')
				$('.l-nav__btn').addClass('is-change')
			}else {
				$('.nav-member').removeClass('is-change')
				$('.l-nav__btn').removeClass('is-change')
			}
		}
	}

	function subNav() {
		const header_h = $('.l-header').outerHeight();
		if(window.innerWidth > BREAK_POINTS.ltSP) {
			$subNav.mouseover(function(){
				$(this).children('.js-nav-sub-trigger').stop().fadeIn();
				$(this).children('.nav-sub').css({
					top: header_h,
				})
			});
			$subNav.mouseout(function(){
				$(this).children('.js-nav-sub-trigger').stop().fadeOut();
			});
		}
	}


	function subNavSP() {
		if(window.innerWidth < BREAK_POINTS.ltSP) {
			$subNav.on('click',function(){
				$(this).toggleClass('is-show');
				if ($(this).hasClass('is-show')) {
					$(this).children('.js-nav-sub-trigger').slideDown();
				}else {
					$(this).children('.js-nav-sub-trigger').slideUp();
				}
			});
		}
	}

	$(function(){
		setHeaderNav();
		closeBtn();
		subNavSP()
	});

	$(window).bind('load scroll resize orientationchange', function () {
		changeNav();
		subNav();
	});
}
